/* eslint-disable react/no-array-index-key */
import { Button, Input } from '@components/atoms'
import { FormRegistrationStep2Context } from './context'
import { useContextSelector } from 'use-context-selector'
import { MenuItem, Select, Stack, Typography } from '@mui/material'
import sectors from '@utils/forms/sectors.json'
import employeeOptions from '@utils/forms/employees.json'
import { ErrorLabel } from '@components/atoms/ErrorLabel'
import _ from 'lodash'
import positions from '@utils/forms/positions.json'
import { SignUpForm } from '@pages/public/Signupemail/styles'
import { useAuth } from '@contexts/Auth'

export function FormRegistrationStep2View(): JSX.Element {
  const { isLogout } = useAuth()
  const formik = useContextSelector(
    FormRegistrationStep2Context,
    (s) => s.formik
  )

  const handleLogout = () => {
    isLogout()
  }

  return (
    <SignUpForm data-cy="signin-step-registration-company">
      <Stack direction="column" spacing={1} alignItems="center">
        <Typography
          textAlign="center"
          variant="$font-title-3"
          color="$color-text-primary"
        >
          Acesso verificado!
          <br />
          Conclua o seu cadastro.
        </Typography>
        <Typography
          textAlign="center"
          variant="$font-body-base"
          color="$color-text-secundary"
        >
          Falta muito pouco para você acessar sua conta.
          <br />
          Finalize o seu cadastro e tenha acesso total ao Nomos!
        </Typography>
      </Stack>
      <Stack direction="column" spacing={1} sx={{ width: '100%' }}>
        <Stack direction="column" spacing={1}>
          <Typography variant="$font-body-base" color="$color-text-primary">
            Nome da empresa
          </Typography>
          <Input
            id="companyName"
            name="companyName"
            size="small"
            placeholder="Digite o nome da empresa que trabalha"
            value={formik.values.companyName}
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.companyName && Boolean(formik.errors.companyName)
            }
            helpertext={formik.touched.companyName && formik.errors.companyName}
          />
        </Stack>
        <Stack direction="column" spacing={1}>
          <Typography variant="$font-body-base" color="$color-text-primary">
            Segmento de atuação
          </Typography>
          <Select
            id="aboutSector"
            name="aboutSector"
            size="small"
            value={formik.values.aboutSector}
            error={
              formik.touched.aboutSector && Boolean(formik.errors.aboutSector)
            }
            data-cy="aboutSector"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            displayEmpty
            disabled={formik.isSubmitting}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <Typography
                    variant="$font-body-base"
                    color="$color-text-tertiary"
                  >
                    Selecione uma das opções
                  </Typography>
                )
              }
              return _.get(sectors, selected)
            }}
          >
            {Object.keys(sectors).map((sectorKey, index) => (
              <MenuItem
                key={`sector-${sectorKey}`}
                value={sectorKey}
                data-cy={`companySector-option-${index}`}
              >
                <Typography variant="$font-body-base">
                  {_.get(sectors, sectorKey)}
                </Typography>
              </MenuItem>
            ))}
          </Select>
          <ErrorLabel
            value={
              (formik.touched.aboutSector &&
                formik.errors.aboutSector) as unknown as undefined
            }
          />
        </Stack>
        <Stack direction="column" spacing={1}>
          <Typography variant="$font-body-base" color="$color-text-primary">
            Número de funcionários
          </Typography>
          <Select
            id="aboutEmployees"
            name="aboutEmployees"
            size="small"
            value={formik.values.aboutEmployees}
            error={
              formik.touched.aboutEmployees &&
              Boolean(formik.errors.aboutEmployees)
            }
            data-cy="aboutEmployees"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            displayEmpty
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <Typography
                    variant="$font-body-base"
                    color="$color-text-tertiary"
                  >
                    Selecione uma das opções
                  </Typography>
                )
              }
              return selected
            }}
          >
            {employeeOptions.map((option, index) => (
              <MenuItem
                value={option}
                key={`companyEmployees-option-${index}`}
                data-cy={`companyEmployees-option-${index}`}
              >
                <Typography variant="$font-body-base">{option}</Typography>
              </MenuItem>
            ))}
          </Select>
          <ErrorLabel
            value={
              (formik.touched.aboutEmployees &&
                formik.errors.aboutEmployees) as unknown as undefined
            }
          />
        </Stack>
        <Stack direction="column" spacing={1}>
          <Typography variant="$font-body-base" color="$color-text-primary">
            Cargo
          </Typography>
          <Select
            size="small"
            id="aboutOfficePosition"
            name="aboutOfficePosition"
            data-cy="aboutOfficePosition"
            value={formik.values.aboutOfficePosition}
            error={
              formik.touched.aboutOfficePosition &&
              Boolean(formik.errors.aboutOfficePosition)
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            displayEmpty
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <Typography
                    variant="$font-body-base"
                    color="$color-text-tertiary"
                  >
                    Selecione uma das opções
                  </Typography>
                )
              }
              return selected
            }}
          >
            {positions.map((position, index) => (
              <MenuItem
                value={position}
                key={`office-position-option-${index}`}
                data-cy={`office-position-option-${index}`}
              >
                <Typography variant="$font-body-base">{position}</Typography>
              </MenuItem>
            ))}
          </Select>
          <ErrorLabel
            value={
              (formik.touched.aboutOfficePosition &&
                formik.errors.aboutOfficePosition) as unknown as undefined
            }
          />
        </Stack>
      </Stack>
      <Stack
        sx={{ width: '100%' }}
        spacing={2}
        direction={{ xs: 'row', sm: 'row' }}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        justifyContent="space-between"
      >
        <Button
          type="button"
          size="medium"
          sx={{ px: 3, width: { xs: '100%', sm: 'auto' } }}
          variant="contained"
          text="Logout"
          onClick={() => handleLogout()}
          disabled={formik.isSubmitting}
          id="btn-submit-user-registration"
        />
        <Button
          type="button"
          size="medium"
          sx={{ px: 3, width: { xs: '100%', sm: 'auto' } }}
          variant="contained"
          text="Concluir"
          onClick={() => formik.handleSubmit()}
          loading={formik.isSubmitting}
          disabled={!formik.isValid || formik.isSubmitting}
          id="btn-submit-user-registration"
        />
      </Stack>
    </SignUpForm>
  )
}
