import { MyAccountContext } from '../../../context'
import { Button, Input, SizedComponent } from '@components/atoms'
import { useContextSelector } from 'use-context-selector'
import { Grid, Stack, Typography, Radio } from '@mui/material'
import { useState } from 'react'

import {
  Container,
  Content,
  InfoLabel,
  Inputs,
  PasswordRequirementsWrapper,
  RoleCard,
  RoleLabel,
  Roles,
} from './UserData.styles'
import InputPhone from '@components/atoms/InputPhone'
import {
  NomosProductCodeEnum,
  NomosProductDesc,
  NomosProductRoleName,
} from '@enums/NomosProductEnum'
import PasswordRequirements from '@components/atoms/PasswordRequirements'
import { useAuth } from '@contexts/Auth'

export default function UserData() {
  const { user } = useAuth()
  const formik = useContextSelector(MyAccountContext, (s) => s.formik)
  const [showPasswordFields, setShowPasswordFields] = useState(false)
  const [slim, setSlim] = useState(false)

  return (
    <SizedComponent
      onChange={({ width }) => {
        setSlim(width < 900)
      }}
    >
      <Container>
        <Content>
          <Typography variant="$font-title-4" color="$color-text-primary">
            Dados de Acesso
          </Typography>
        </Content>
        <Content>
          <InfoLabel>
            <Typography variant="$font-title-5" color="$color-text-primary">
              Informações do usuário
            </Typography>
            <Typography variant="$font-body-base" color="$color-text-secundary">
              Consulte e gerencie suas <br /> informações pessoais
            </Typography>
          </InfoLabel>
          <Inputs>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Input
                  id="name"
                  name="name"
                  size="small"
                  data-cy="name"
                  label="Nome e Sobrenome"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helpertext={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={slim ? 12 : 6}>
                <Input
                  id="email"
                  data-cy="email"
                  size="small"
                  name="email"
                  label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helpertext={formik.touched.email && formik.errors.email}
                  disabled
                />
              </Grid>
              <Grid item xs={slim ? 12 : 6}>
                <InputPhone
                  id="phone"
                  data-cy="phone"
                  size="small"
                  name="phone"
                  label="Contato"
                  placeholder="(__) _____-____"
                  value={formik.values.phone}
                  onBlur={formik.handleBlur}
                  onChange={(value) => formik.setFieldValue('phone', value)}
                  error={formik.touched.phone && formik.errors.phone}
                />
              </Grid>
              {showPasswordFields && (
                <>
                  <Grid item xs={slim ? 12 : 6}>
                    <Input
                      type="password"
                      id="password"
                      name="password"
                      label="Nova Senha"
                      placeholder="Senha (pelo menos 8 caracteres)"
                      size="small"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                    />
                    {formik.values.password?.length > 0 && (
                      <PasswordRequirementsWrapper>
                        <PasswordRequirements
                          password={formik.values.password}
                          showLabel={false}
                        />
                      </PasswordRequirementsWrapper>
                    )}
                  </Grid>
                  <Grid item xs={slim ? 12 : 6}>
                    <Input
                      type="password"
                      id="password_confirmation"
                      name="password_confirmation"
                      label="Confirmar Senha"
                      placeholder="Confirme a nova senha"
                      size="small"
                      value={formik.values.password_confirmation}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.password_confirmation &&
                        Boolean(formik.errors.password_confirmation)
                      }
                      helpertext={
                        formik.touched.password_confirmation &&
                        formik.errors.password_confirmation
                      }
                      fullWidth
                    />
                  </Grid>
                </>
              )}

              {!showPasswordFields && (
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      cursor: 'pointer',
                      color: '$color-action-link',
                      display: 'inline-block',
                      width: 'auto',
                      marginTop: '10px',
                    }}
                    onClick={() => {
                      setShowPasswordFields(true)
                    }}
                  >
                    Alterar senha
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Stack alignItems="flex-start">
              <Button
                data-cy="submit"
                type="submit"
                text="Salvar alterações"
                disabled={formik.isSubmitting || !formik.isValid}
                loading={formik.isSubmitting ? true : undefined}
                onClick={() => {
                  formik.handleSubmit()
                  setShowPasswordFields(false)
                }}
              />
            </Stack>
          </Inputs>
        </Content>

        <Stack direction="column" spacing={2}>
          <Content>
            <InfoLabel>
              <Typography variant="$font-title-5" color="$color-text-primary">
                Perfil
              </Typography>
              <Typography
                variant="$font-body-base"
                color="$color-text-secundary"
              >
                Consulte seu perfil de <br /> acesso ao Nomos
              </Typography>
            </InfoLabel>

            <Roles
              name="role-options-group"
              value={formik.values.admin}
              onChange={formik.handleChange}
            >
              <RoleCard type="bordored">
                <RoleLabel>
                  <Typography
                    variant="$font-body-base"
                    color="$color-text-primary"
                  >
                    Administrador
                  </Typography>
                  <Typography
                    variant="$font-body-base"
                    color="$color-text-secundary"
                  >
                    Concede permissão para o usuário adicionar novos membros,
                    gerenciar a organização, cobranças e assinaturas.
                  </Typography>
                </RoleLabel>
                <Radio value="true" checked={formik.values.admin} disabled />
              </RoleCard>

              <RoleCard type="bordored">
                <RoleLabel>
                  <Typography
                    variant="$font-body-base"
                    color="$color-text-primary"
                  >
                    Membro
                  </Typography>
                  <Typography
                    variant="$font-body-base"
                    color="$color-text-secundary"
                  >
                    Apenas pode navegar pelos recursos disponíveis na nomos de
                    acordo com suas permissões.
                  </Typography>
                </RoleLabel>
                <Radio value="false" checked={!formik.values.admin} disabled />
              </RoleCard>
            </Roles>
          </Content>

          <Content>
            <InfoLabel>
              <Typography variant="$font-title-5" color="$color-text-primary">
                Permissão
              </Typography>
              <Typography
                variant="$font-body-base"
                color="$color-text-secundary"
              >
                Consulte sua permissão de <br /> acesso ao Nomos
              </Typography>
            </InfoLabel>

            <Roles name="role-options-group" value={formik.values.role}>
              <RoleCard type="bordored">
                <RoleLabel>
                  <Typography
                    variant="$font-body-base"
                    color="$color-text-primary"
                  >
                    {NomosProductRoleName[NomosProductCodeEnum.MONITOR_PRO]}
                  </Typography>
                  <Typography
                    variant="$font-body-base"
                    color="$color-text-secundary"
                  >
                    {NomosProductDesc[NomosProductCodeEnum.MONITOR_PRO]}
                  </Typography>
                </RoleLabel>
                <Radio
                  checked={user?.role === NomosProductCodeEnum.MONITOR_PRO}
                  disabled
                />
              </RoleCard>

              <RoleCard type="bordored">
                <RoleLabel>
                  <Typography
                    variant="$font-body-base"
                    color="$color-text-primary"
                  >
                    {NomosProductRoleName[NomosProductCodeEnum.MONITOR_LIGHT]}
                  </Typography>
                  <Typography
                    variant="$font-body-base"
                    color="$color-text-secundary"
                  >
                    {NomosProductDesc[NomosProductCodeEnum.MONITOR_LIGHT]}
                  </Typography>
                </RoleLabel>
                <Radio
                  checked={user?.role === NomosProductCodeEnum.MONITOR_LIGHT}
                  disabled
                />
              </RoleCard>
            </Roles>
          </Content>
        </Stack>
      </Container>
    </SizedComponent>
  )
}
